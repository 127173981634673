<template>
  <v-app>
    <v-main>
      <v-container class="pa-0" :class="{ black: !useTiktokStyles }" fluid fill-height>
        <v-row v-if="useTiktokStyles" justify="center" align="center">
          <v-progress-circular
            :color="tiktokRed"
            size="24"
            width="2"
            indeterminate
          />
        </v-row>
        <v-row v-else id="v-app" justify="center" align="center">
          <h1 class="mr-2 white--text">Please wait...</h1>
          <v-progress-circular indeterminate color="white" />
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { TIKTOK_COLORS } from '@/data/constants';

  export default {
    data: () => ({
      tiktokRed: TIKTOK_COLORS.RED,
    }),
  }
</script>

<style></style>
