const initialState = {
  focus: 0,
  settingsErrors: {
    title: false,
    confirmation: false,
    customText: {
      prevLabel: false,
      nextLabel: false,
    },
  },
  inputErrors: [],
  settingsTab: 0,
  autoSave: {
    lastSave: null,
    saveCount: 0,
    surveyId: null,
  },
};

const mutations = {
  setFocus(state, payload) {
    state.focus = payload;
  },

  setSettingsErrors(state, payload) {
    state.settingsErrors = payload;
  },

  setSettingsTab(state, payload) {
    state.settingsTab = payload;
  },

  inputErrors(state, payload) {
    state.inputErrors = payload;
  },

  setAutoSave(state, payload) {
    state.autoSave = payload;
  },

  resetAutoSave(state) {
    state.autoSave = {
      lastSave: null,
      saveCount: 0,
      surveyId: null,
    };
  },
};

const actions = {
  setFocus(context, payload) {
    context.commit('setFocus', payload);
  },

  setSettingsErrors(context, payload) {
    context.commit('setSettingsErrors', payload);
  },

  setSettingsTab(context, payload) {
    context.commit('setSettingsTab', payload);
  },

  inputErrors(context, payload) {
    context.commit('setInputErrors', payload);
  },

  setAutoSave(context, payload) {
    context.commit('setAutoSave', payload);
  },

  resetAutoSave(context) {
    context.commit('resetAutoSave');
  },
};

const getters = {
  focus: (state) => state.focus,
  settingsErrors: (state) => state.settingsErrors,
  inputErrors: (state) => state.settingsErrors,
  settingsTab: (state) => state.settingsTab,
  autoSave: (state) => state.autoSave,
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
