import {
  // Timezones
  TIMEZONES,
  CURRENCIES,
  // User Roles
  USER_ROLES,
  // Survey Form
  FORM_COMPONENT_TYPES,
  FORM_COMPONENT_TYPES_SIDE,
  FORM_COMPONENT_ICONS,
  FORM_COMPONENT_COLORS,
  QUESTION_TEMPLATES,
  FORM_HELPER_TEXT,
  // Social Media Colors
  SOCIAL_COLORS,
  // Survey Settings
  DISCOUNT_UNITS,
  // Integrations
  SERVICES,
} from '@/data/constants';

export default {
  computed: {
    // Global
    SERVICES() {
      return SERVICES;
    },
    // Timezones
    TIMEZONES() {
      return TIMEZONES;
    },
    CURRENCIES() {
      return CURRENCIES;
    },
    // User Roles
    USER_ROLES() {
      return USER_ROLES;
    },
    // Survey Form
    FORM_COMPONENT_TYPES() {
      return FORM_COMPONENT_TYPES;
    },
    FORM_COMPONENT_TYPES_SIDE() {
      return FORM_COMPONENT_TYPES_SIDE;
    },
    FORM_HELPER_TEXT() {
      return FORM_HELPER_TEXT;
    },
    FORM_COMPONENT_ICONS() {
      return FORM_COMPONENT_ICONS;
    },
    FORM_COMPONENT_COLORS() {
      return FORM_COMPONENT_COLORS;
    },
    QUESTION_TEMPLATES() {
      return QUESTION_TEMPLATES;
    },
    // Social Media Colors
    SOCIAL_COLORS() {
      return SOCIAL_COLORS;
    },
    DISCOUNT_UNITS() {
      return DISCOUNT_UNITS;
    },
  },
};
