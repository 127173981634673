const MONTHS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

const list = {
  'MM/DD/YYYY': {
    format: 'MM/DD/YYYY',
    idx: [2, 5],
    type: '/',
    regex: /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/,
  },
  'DD.MM.YYYY': {
    format: 'DD.MM.YYYY',
    idx: [2, 5],
    type: '.',
    regex: /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/,
  },
  'YYYY-MM-DD': {
    format: 'YYYY-MM-DD',
    idx: [4, 7],
    type: '-',
    regex: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
  },
  'MMMM DD': {
    format: 'MMMM DD',
    idx: [-1],
    type: ' ',
    regex: /^(\w+)(\s)(0[1-9]|[12][0-9]|3[01])$/i,
  },
};

/*
const autocomplete = (val) => {
  const match = [];
  for (let i = 0; i < MONTHS.length; i += 1) {
    if (val === MONTHS[i].slice(0, val.length)) {
      match.push(MONTHS[i]);
    }
  }
  return match;
};
*/

const mask = (v, format) => {
  const ISO8601 = list['YYYY-MM-DD'];
  const f = list[format] || ISO8601;
  let result = '';
  if (format === 'MMMM DD') {
    // TODO: autocomplete for long month format
    /*
    const [MMMM, DD] = v.split(' ');
    if (!v.test(/\w*\s/) && MMMM && !DD) {
      const t = autocomplete(MMMM);
      console.log(t);
    }
    */
    result = v;
  } else {
    const value = v.replace(/[\D]/g, '');
    for (let i = 0; i < value.length; i += 1) {
      if (f.idx[0] - 1 === i || f.idx[1] === i + 2) {
        result += `${value[i]}${f.type}`;
      } else {
        result += `${value[i]}`;
      }
    }

    if (result.length >= f.format.length - 1) {
      result = result.slice(0, f.format.length);
    }
  }
  return result;
};

const fromISO8601 = (value, format) => {
  if (!value) return '';
  const [year, month, day] = value.split('-');
  const date = new Date(year, month - 1, day);
  const YYYY = date.getFullYear();
  let MM = date.getMonth() + 1; // zero based value
  let DD = date.getDate();
  const MMMM = MONTHS[MM - 1];
  MM = MM > 9 ? MM : `0${MM}`;
  DD = DD > 9 ? DD : `0${DD}`;
  return {
    'MM/DD/YYYY': `${MM}/${DD}/${YYYY}`,
    'DD.MM.YYYY': `${DD}.${MM}.${YYYY}`,
    'MMMM DD': `${MMMM} ${DD}`,
    'YYYY-MM-DD': `${YYYY}-${MM}-${DD}`, // ISO8601 default date input format
  }[format] || `${YYYY}-${MM}-${DD}`;
};

const toISO8601 = (value, format) => {
  let ISO8601 = null;
  const f = list[format];
  const sameLen = f.format.length === value.length;
  if (f.regex.test(value)) {
    if (f.format === 'MM/DD/YYYY' && sameLen) {
      const [MM, DD, YYYY] = value.split('/');
      ISO8601 = `${YYYY}-${MM}-${DD}`;
    }
    if (f.format === 'DD.MM.YYYY' && sameLen) {
      const [DD, MM, YYYY] = value.split('.');
      ISO8601 = `${YYYY}-${MM}-${DD}`;
    }
    if (f.format === 'YYYY-MM-DD' && sameLen) {
      ISO8601 = value;
    }
    if (f.format === 'MMMM DD') {
      const [MMMM, DD] = value.split(' ');
      // assume current year
      const now = new Date();
      const YYYY = now.getFullYear();
      const date = new Date(`${DD} ${MMMM} ${YYYY}`);
      const MM = date.getMonth() + 1; // account for zero index
      ISO8601 = `${YYYY}-${MM}-${DD}`;
    }
  }
  return ISO8601;
};

const dateFormat = {
  MONTHS,
  list,
  mask,
  fromISO8601,
  toISO8601,
};

export default dateFormat;
