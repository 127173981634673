export const AuthService = {
  methods: {
    validateEmail(payload) {
      return $http.post('auth/validate-email', payload);
    },
    login(payload) {
      return $http.post('auth/login', payload);
    },
    register(payload) {
      return $http.post('auth/register', payload);
    },
    acceptInvitation(payload) {
      return $http.post('auth/accept-invitation', payload);
    },
    requestPasswordReset(payload) {
      return $http.post('auth/password-reset', payload);
    },
    passwordReset(token, payload) {
      return $http.put(`auth/password-reset/${token}`, payload);
    },
    loginWithGoogle(payload) {
      return $http.post('auth/google/login', payload);
    },
    registerWithGoogle(payload) {
      return $http.post('auth/google/register', payload);
    },
    acceptInvitationWithGoogle(payload) {
      return $http.post('auth/google/accept-invitation', payload);
    },
    /* Request Shopify Redirect URL */
    connectToShopify(payload, params) {
      return $http.post('auth/shopify/connect', payload, { params });
    },
    /* Handle Shopify Oauth flow */
    loginWithShopify(payload) {
      return $http.post('auth/shopify/login', payload);
    },
    /* Request Tiktok Redirect URL */
    connectToTiktok(payload) {
      return $http.post('auth/tiktok/connect', payload);
    },
    /* Complete TikTok Integration or Login */
    tiktokSSO(payload) {
      return $http.post('auth/tiktok/sso', payload);
    },
    /* Request Yotpo Redirect URL */
    connectToYotpo() {
      return $http.get('auth/yotpo/connect');
    },
    getTiktokAdvertiserURL(advertiserId) {
      return $http.get(`auth/tiktok/advertiser/${advertiserId}/url`);
    },
  },
};

export default AuthService;
