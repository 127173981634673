import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
    values: {
      sort: 'mdi-chevron-down',
    },
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#353535',
        secondary: '#CFCFCD',
        accent: '#EFFF01',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
      dark: {
        primary: '#000000',
        secondary: '#353535',
        accent: '#EFFF01',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
    options: {
      customProperties: true,
    },
  },
});
