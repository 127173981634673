export default {
  methods: {
    getProfile() {
      return $http.get('profile');
    },
    patchProfile(payload) {
      return $http.patch('profile', payload);
    },
    changePassword(payload) {
      return $http.post('profile/change-password', payload);
    },
    getAccountPlan() {
      return $http.get('profile/plan');
    },
    updateSubscription(payload) {
      return $http.post('profile/subscription', payload);
    },
    deleteSubscription() {
      return $http.delete('profile/subscription');
    },
    createShopifySubscription(payload) {
      return $http.post('profile/subscription/shopify', payload);
    },
    createStripeSubscription(payload) {
      return $http.post('profile/subscription/stripe', payload);
    },
    createStripePortalSession() {
      return $http.post('profile/subscription/stripe/portal');
    },
    getProfilePlans(params) {
      return $http.get('profile/plans', params);
    },
    uploadProfileImage(payload) {
      return $http.post(
        'profile/photo-upload',
        payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
      );
    },
  },
};
