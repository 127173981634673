/* https://developers.intercom.com/installing-intercom/web/methods/#intercomboot-intercomsettings
 * https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app#enabling-identity-verification-in-a-single-page-app
 */
export default {
  methods: {
    formatPayload(obj) {

      const invalidValue = (v) => ['', null, undefined].includes(v);

      Object.entries(obj).forEach(([key, value]) => {
        if (typeof value !== 'object' && invalidValue(value)) {
          // console.warn(`[Kno] Invalid intercom payload value ${value} for key ${key}`);
          delete obj[key];
        }
      });

      return obj;
    },
    unixTimestamp(date) {
      date = date ? new Date(date) : new Date();
      return parseInt(date.getTime() / 1000);
    },
    buildIntercomCustomAttributes(account = null) {
      // TODO:
      return this.formatPayload({
        creation_source: account?.metadata?.source,
        company_hasCustom: account?.hasCustom ?? false,
        company_hasShopify: account?.hasShopify ?? false,
        company_hasShopifyTagging: account?.hasShopifyTagging ?? false,
        company_hasKlaviyo: account?.hasKlaviyo ?? false,
        company_hasSurvey: account?.hasSurvey ?? false,
        company_active_audiences: account?.tier?.activeAudiences ?? 0,
        company_active_surveys: account?.tier?.activeExperiences ?? 0,
      });
    },
    buildIntercomSettings(user, account = null, companyCustomAttributes = {}) {
      if (!user) return {}

      const userAttributes = {
        user_hash: user?.intercom, // required for boot logged in user
        user_id: user?.id,         // required for boot logged in user
        email: user?.email,        // required for boot logged in user
        name: user?.name,
      }

      if (account) {
        // https://developers.intercom.com/installing-intercom/web/attributes-objects/#company-object
        const companyAttributes = {
          company_id: account?.id,
          name: account?.name,
          plan: account?.plan?.name,
          plan_lookup_key: account?.plan?.lookupKey,
          // Custom Company Attributes
          creation_source: account?.metadata?.source, // required
          // TODO:
          company_hasShopify: false,
          company_hasKlaviyo: false,
          company_hasSurvey: false,
        }
        userAttributes.company = this.formatPayload(companyAttributes);
      }

      return userAttributes;
    },
    bootIntercom(user, account) {
      /* NOTE: call this when user to login/convert intercom lead/visitor to a intercom user,
       * simply change the boot method to include their user record info, id, hash, email required
       */
      try {
        const settings = {
          app_id: process.env.VUE_APP_INTERCOM_APP_ID, // required
          ...this.buildIntercomSettings(user, account)
        };
        // console.info('intercom boot', settings);
        window.Intercom('boot', settings);
      } catch(err) {
        console.error(err);
      }
    },
    updateIntercom(user, account, companyData) {

      /* NOTE: Update Intercom whenever the view or URL changes in your app.
       * This will allow people to receive your most recent messages.
       * always include user.hash, user.id, user.email ... the other data can only be what
       * changed with this update (usually this is account records not user records)
       * "Note: The update call is limited to 10 times per user per page without getting throttled.
       *  If you exceed this rate, you'll be allowed to call once in 30 minutes.
       *  A page refresh or reload will reset this.
       *  We recommend updating only in places where there is a user data change sent to Intercom
       *  that could trigger an Auto Message for this reason."
       */
      if (!user) return;

      if (!user?.id || !user?.intercom) {
        return console.warn('Intercom identifier required');
      }

      const payload = this.buildIntercomSettings(user, account, companyData);
      // console.info('intercom update', payload);
      window.Intercom('update', payload);
    },
    changedURL() {
      const payload = { last_request_at: parseInt((new Date()).getTime() / 1000) };
      // console.debug('changedURL', payload)
      window.Intercom('update', payload);
    },
    intercomTrackEvent(eventName, payload) {
      window.Intercom('trackEvent', eventName, payload);
    },
    shutdownIntercom() {
      window.Intercom('shutdown');
    },
    trackEventIntercom(event, metadata) {
      window.Intercom('trackEvent', event, metadata);
    }
  },
};
