// interface AppState {
//   isMenuSidebarOpen: boolean | null;
//   integrations: Array<Integration>;
//   plan: Plan | null;
//   showUpgradeModal: boolean | null;
// }
//
// interface Integration {
//   id: number;
//   serviceType: string;
//   data: any,
// }
//
// interface Plan {
//   id: number;
//   amount: number;
//   name: string;
//   trialDays: number;
//   maxActiveAudiences: number;
//   maxActiveExperiences: number;
//   maxQuestionsPerExperience: number;
//   allowActions: boolean;
//   allowSurveyLinks: boolean;
//   allowRemoveBranding: boolean;
// }
import { UPGRADE_BLURB } from '@/data/constants';

const initialState = {
  isMenuSidebarOpen: null,
  integrations: [],
  plan: null,
  showUpgradeModal: false,
  upgradeModalContent: '',
  upgradeModalPersistent: false,
  savedRoute: null,
};

const mutations = {
  setMenuSidebarOpen(state, payload) {
    state.isMenuSidebarOpen = payload;
  },
  setPlan(state, payload) {
    state.plan = payload;
  },
  setIntegrations(state, payload) {
    state.integrations = payload;
  },
  setShowUpgradeModal(state, payload) {
    state.showUpgradeModal = payload;
  },
  setUpgradeModalContent(state, payload) {
    state.upgradeModalContent = payload;
  },
  resetAppState(state) {
    Object.assign(state, initialState);
  },
  setSavedRoute(state, payload) {
    state.savedRoute = payload;
  },
  setUpgradeModalPersistent(state, payload) {
    state.upgradeModalPersistent = payload;
  },
};

const actions = {
  setMenuSidebarOpen(context, payload) {
    context.commit('setMenuSidebarOpen', payload);
  },
  setPlan(context, payload) {
    context.commit('setPlan', payload);
  },
  setIntegrations(context, payload) {
    context.commit('setIntegrations', payload);
  },
  setShowUpgradeModal(context, payload) {
    context.commit('setShowUpgradeModal', payload);
  },
  resetAppState(context) {
    context.commit('resetAppState');
  },
  setSavedRoute(context, payload) {
    context.commit('setSavedRoute', payload);
  },
  changeUpgradeModal(context, payload) {
    const { show, type, persistent } = payload;
    const blurb = UPGRADE_BLURB[type];

    if (show != null) {
      context.commit('setShowUpgradeModal', show);
    }

    if (blurb != null) {
      context.commit('setUpgradeModalContent', blurb);
    }

    context.commit('setUpgradeModalPersistent', !!persistent);
  },
};

const getters = {
  plan: (state) => state.plan,
  integrations: (state) => state.integrations,
  showUpgradeModal: (state) => state.showUpgradeModal,
  savedRoute: (state) => state.savedRoute,
  upgradeModalContent: (state) => state.upgradeModalContent,
  upgradeModalPersistent: (state) => state.upgradeModalPersistent,
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
