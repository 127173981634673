import Vue from 'vue';

const initialState = {
  accountData: null,
  userData: null,
  tiktokConnectState: {
    advertiserId: null,
    advertiserURL: null,
    advertiserVerified: null,
    advertiserEvent: null,
    completedAt: null,
    plan: null,
    shop: null,
    step: 0,
    integrationId: null,
    loading: false,
  },
};

const mutations = {
  setAccountData(state, payload) {
    state.accountData = { ...state.accountData, ...payload };
  },
  setUserData(state, payload) {
    state.userData = { ...state.userData, ...payload };
  },
  setTiktokConnectState(state, payload) {
    state.tiktokConnectState = { ...state.tiktokConnectState, ...payload };
  },
  setTiktokLoader(state, loading) {
    Vue.set(state.tiktokConnectState, 'loading', loading);
  },
  resetAccountData(state) {
    state.accountData = null;
  },
  resetAuthState(state) {
    Object.assign(state, initialState);
  },
};

const actions = {
  setAccountData(context, payload) {
    context.commit('setAccountData', payload);
  },
  setUserData(context, payload) {
    context.commit('setUserData', payload);
  },
  setTiktokConnectState(context, payload) {
    context.commit('setTiktokConnectState', payload);
  },
  setTiktokLoader(context, loading) {
    context.commit('setTiktokLoader', loading);
  },
  resetAccountData(context) {
    context.commit('resetAccountData');
  },
  resetAuthState(context) {
    context.commit('resetAuthState');
  },
};

const getters = {
  getAccountData: (state) => state.accountData,
  getTiktokConnectState: (state) => state.tiktokConnectState,
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
