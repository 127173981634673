import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import appStore from './modules/app';
import authStore from './modules/auth';
import builderStore from './modules/builder';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    appStore,
    authStore,
    builderStore,
  },
  plugins: [createPersistedState()],
});
