<template>
  <div v-if="loading">
    <loading-screen />
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import LoadingScreen from '@/components/LoadingScreen.vue';
import IntercomService from '@/services/IntercomService';

export default {
  name: 'App',
  components: {
    LoadingScreen,
  },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    this.syncProfile();
  },
  mixins: [IntercomService],
  methods: {
    async syncProfile() {

      if ($helpers.isAuthenticated()) {
        /* Reset store state */
        const profile = await this.fetchCurrentProfile();
        this.bootIntercom(profile?.user, profile);
      } else {
        this.bootIntercom();
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/app.scss';
</style>
