// @ts-nocheck
import Vue from 'vue';
import VueGtag from 'vue-gtag';
import VDatetimePicker from 'vuetify-datetime-picker';
import VueApexCharts from 'vue-apexcharts';
import VueMask from 'v-mask';
import * as Sentry from "@sentry/vue";
import toastr from 'toastr';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import PlanUpgradeModal from './components/Main/Shared/PlanUpgradeModal.vue';
import App from './App.vue';
import MaintenancePage from './pages/MaintenancePage.vue';
import './helpers';
import './filters';
import './services/ApiService';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import AppMixin from './mixins/AppMixin';
import DataMixin from './mixins/DataMixin';
import '@fortawesome/fontawesome-pro/css/all.min.css'; // Ensure you are using css-loader
// eslint-disable-next-line
import 'toastr/build/toastr.min.css';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
(function () {
    var _a;
    if (process.env.VUE_APP_MAINTENANCE === 'true') {
        new Vue({ vuetify: vuetify, render: function (h) { return h(MaintenancePage); } }).$mount('#app');
        return;
    }
    var queryParams = new URLSearchParams(window.location.search);
    var shop = queryParams.get('shop');
    if (window.location.pathname === '/' && shop) {
        return $helpers.connectApp(shop);
    }
    if (window.location.pathname.includes('/main/settings/integrations/yotpo')) {
        localStorage.setItem('integrationRedirect', 'yotpo');
    }
    Vue.config.productionTip = false;
    /* Instance properties */
    toastr.options = { maxOpened: 1 };
    Vue.prototype.$toastr = toastr;
    /* NOTE: these could also live in vue.config.js */
    Vue.prototype.$editorKey = process.env.VUE_APP_TINY_EDITOR;
    Vue.prototype.$embedURL = process.env.VUE_APP_EMBED_URL;
    Vue.prototype.$apiPermissions = [
        'ATTRIBUTION',
        'RESPONSES',
        'SURVEYS',
        'WEBHOOKS',
        'QUESTIONS',
    ];
    // TODO: move these permissions to the database + allow super admin crud?
    Vue.mixin(AppMixin);
    Vue.mixin(DataMixin);
    // Plugins
    Vue.use(VDatetimePicker);
    Vue.use(VueApexCharts);
    Vue.use(VueMask);
    // Register Global Components
    Vue.component('Apexchart', VueApexCharts);
    Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
    Vue.component('PlanUpgradeModal', PlanUpgradeModal);
    if (process.env.VUE_APP_GOOGLE_TAG_ID) {
        Vue.use(VueGtag, {
            /* Lets keep things simple and not create an enviroment variable */
            includes: [{ id: 'AW-346883124' }],
            config: { id: process.env.VUE_APP_GOOGLE_TAG_ID }
        }, router);
    }
    if (process.env.VUE_APP_SENTRY_DSN) {
        Sentry.init({
            Vue: Vue,
            dsn: process.env.VUE_APP_SENTRY_DSN,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.captureConsoleIntegration(),
                // TODO: Sentry.replayIntegration(), // what are the pricing implications of this?
            ],
            // Performance Monitoring
            tracesSampleRate: (_a = process.env.VUE_APP_SENTRY_TRACE_RATE) !== null && _a !== void 0 ? _a : 0.1,
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', /^https:\/\/(app-api|dev-api)\.knocommerce\.com\/api\//],
            // TODO:? Session Replay
            // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }
    new Vue({
        vuetify: vuetify,
        router: router,
        store: store,
        render: function (h) { return h(App); },
    }).$mount('#app');
})();
