import Vue from 'vue';
import moment from 'moment-timezone';

Vue.filter('dateFormatter', (value, format, timezone = null) => {
  if (!value) return '';
  return moment.tz(value, timezone).format(format);
});

Vue.filter(
  'numberFormatter',
  (value, maximumFractionDigits = 2, minimumFractionDigits = 2) => {
    if (!value) return '0';
    const formatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits,
      minimumFractionDigits,
    });
    return formatter.format(value);
  },
);

Vue.filter(
  'currencyFormatter',
  (value, currency, maximumFractionDigits = 2, minimumFractionDigits = 2) => {
    if (!value) return '0';
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      maximumFractionDigits,
      minimumFractionDigits,
    });
    return formatter.format(value);
  },
);
