import Vue from 'vue';
export var IntegrationService = {
    methods: {
        getIntegrations: function () {
            return $http.get('integrations');
        },
        createShopifyIntegration: function (payload) {
            return $http.post('integrations/shopify', payload);
        },
        createKlaviyoIntegration: function (payload) {
            return $http.post('integrations/klaviyo', payload);
        },
        createIntegration: function (payload) {
            return $http.post('integrations', payload);
        },
        updateIntegration: function (integrationId, payload) {
            return $http.put("integrations/".concat(integrationId), payload);
        },
        deleteIntegration: function (integrationId, params) {
            return $http.delete("integrations/".concat(integrationId), params);
        },
        refreshShopifyScriptTags: function (integrationId, payload) {
            return $http.put("integrations/".concat(integrationId, "/shopify-script-tags/refresh"), payload);
        },
        deleteShopifyScriptTags: function (integrationId, params) {
            return $http.delete("integrations/".concat(integrationId, "/shopify-script-tags/delete"), params);
        },
        getAlloyToken: function () {
            return $http.get('integrations/token');
        },
        updateTiktokAdvertiser: function (payload) {
            return $http.put('integrations/tiktok/advertiser', payload);
        },
        getTiktokAdvertiser: function () {
            return $http.get('integrations/tiktok/advertiser');
        },
        createYotpoIntegration: function (payload) {
            return $http.post('integrations/yotpo', payload);
        },
    },
};
export default Vue.extend(IntegrationService);
