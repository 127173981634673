<template>
  <v-dialog
    overlay-opacity=".95"
    v-model="isModalOpen"
    :max-width="480"
    :persistent="isModalPersistent"
  >
    <v-card dark>
      <v-card-title :class="$style.action">
        <v-spacer />
        <v-icon v-if="!isModalPersistent" @click="close"> mdi-close </v-icon>
      </v-card-title>

      <div :class="$style.content">
        <span :class="$style.title">Upgrade now!</span>
        <span :class="$style.description">{{ upgradeBlurb }}</span>

        <div>
          <v-btn
            elevation="0"
            large
            type="button"
            color="accent"
            :class="$style.cta"
            @click="submit()"
          >
            Upgrade my plan
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { UPGRADE_BLURB } from '@/data/constants';

export default {
  name: 'PlanUpgradeModal',
  computed: {
    isModalOpen: {
      get() {
        return this.$store.getters['appStore/showUpgradeModal'];
      },
      set(value) {
        this.$store.dispatch('appStore/setShowUpgradeModal', value);
      },
    },
    isModalPersistent() {
      return this.$store.getters['appStore/upgradeModalPersistent'] ?? false;
    },
    upgradeBlurb() {
      return (
        this.$store.getters['appStore/upgradeModalContent'] ||
        UPGRADE_BLURB.GENERIC
      );
    },
  },
  methods: {
    close() {
      this.isModalOpen = false;
    },
    async submit() {
      /* Redirects user to plan upgrade flow */
      this.close();
      this.$router.push({
        name: 'subscription-page',
        query: { redirect: true },
      });
    },
  },
};
</script>

<style lang="scss" module>
.content {
  padding: 0 48px 48px;
  text-align: center;

  @media screen and (max-width: 530px) {
    padding: 0 24px 24px;
  }
}

.title {
  display: block;
  font-weight: bold;
  font-size: 2rem;
  margin: 0 0 0.5em;
}

.description {
  display: block;
  font-size: 1rem;
  margin: 0 0 2em;
}

.cta {
  color: rgba(0, 0, 0, 0.75) !important;
  font-weight: bold;
  letter-spacing: 0.75px;
}

.action {
  min-height: 50px;
}
</style>
