import jwtDecode from 'jwt-decode';
import * as uuid from 'uuid';
import AppMixin from '@/mixins/AppMixin';
import AuthService from '@/services/AuthService';
import IntercomService from '@/services/IntercomService';
import { REGISTER_QUESTIONS } from '@/data/constants';

export function getCachedList(key) {
  const items = localStorage.getItem(key);

  if (!items) {
    return [];
  }

  try {
    const parsedItems = JSON.parse(items);
    return Array.isArray(parsedItems) ? parsedItems.filter((id) => !!id) : [];
  } catch (err) {
    return [];
  }
}

export function formatPercentage(value, minimumFractionDigits, maximumFractionDigits) {
  if (!value) return '0%';

  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits,
    minimumFractionDigits,
  });

  return `${formatter.format(value)}%`;
};

window.$helpers = {
  async connectApp(shop) {
    try {
      document.body.style.backgroundColor = '#000';

      const payload = { shop };
      const params = { isShopifyInstall: true };
      localStorage.setItem('source', 'shopify');

      const { data } = await AuthService.methods.connectToShopify(payload, params);

      window.location = data.connectUrl;

    } catch (err) {
      AppMixin.methods.handleErrorResponse(err);
      setTimeout(() => {
        window.location.href = `${window.location.origin}/login`;
      }, 2000);
    }
  },
  getApiToken() {
    const token = localStorage.getItem('token');
    if (!token) return null;
    const decoded = jwtDecode(token);
    if (decoded.exp < Math.floor(Date.now() / 1000)) return null;
    return token;
  },
  isAuthenticated() {
    // NOTE: Request user data to validate user token is valid
    return !!this.getApiToken();
  },
  logout(to) {
    console.debug('logout location', to);
    localStorage.clear();
    window.location = to ?? '/';
    IntercomService.methods.shutdownIntercom();
  },
  generateRandomId() {
    return uuid.v4();
  },
  download(data, filename) {
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      `data:text/plain;charset=utf-8,${encodeURIComponent(`\ufeff${data}`)}`,
    );
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    return true;
  },
  delay(milliseconds) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(), milliseconds);
    });
  },
  isAccountCompleted(account) {
    const details = { ...account.details };
    const questions = REGISTER_QUESTIONS.filter((q) => q.required && details[q.name] == null);
    return questions && questions.length === 0;
  },
  isAccountStatusActive(account, plan) {

    /* If free plan is selected then ignore status */
    if (plan && plan.amount === 0) {
      return true;
    }

    /* TODO: Check status only for paid plans */
    return account.status === 'active' || account.status === 'trialing';
  },
  getCachedList,
  formatPercentage,
};

export default window.$helpers;
